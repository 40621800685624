import routes from '../../routing/routes'
import { State } from '../../store/state'

export const sidebarItems = routes.filter((e) => !e.hiddenInTaskbar)

export const getMenuItemFromHash = (hash: string) => {
  const type = hash.split('/')[1]

  return sidebarItems.map((i) => i.path.split('/')[1]).includes(type) ? type : ''
}

export const recoverSidebar = (state: State) => {
  const data = state.settings.data.get().sidebar

  if (!data.length) {
    storeSidebar(state)
    return
  }

  sidebarItems.splice(0, sidebarItems.length, ...data)
}

export const storeSidebar = (state: State, sidebarData = sidebarItems) => {
  state.settings.upsert({ sidebar: sidebarData })
}
